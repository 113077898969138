<template>
     <div class="seckill_main">
          <div v-if="activity_open && loaded">
               <section class="main_top">
                    <div class="area_12">
                         <nav class=" category flex_row_start_center">
                              <div class="cate_item chosen" @click="changeSort('all', 0)">{{ L['全部'] }}</div>
                              <div class="cate_item" :class="{ chosen: tabCurrent == idx }" v-for="(nav, idx) in nav_list"
                                   :key="idx" @click="changeSort(idx, nav.labelId)">{{
                                        nav.labelName
                                   }}</div>
                         </nav>


                         <div v-if="swiper_list.length" class="swiper_banner">
                              <el-carousel height="457px" arrow="never">
                                   <el-carousel-item v-for="(item_banner, index_banner) in swiper_list" :key="index_banner">
                                        <a href="javascript:void(0)" @click="diyNavTo(item_banner)">
                                             <div :style="`background-image:url(${item_banner.imgUrl})`"></div>
                                        </a>
                                   </el-carousel-item>
                              </el-carousel>
                         </div>


                         <nav class="time_list" v-if="time_list.length">
                              <el-scrollbar>
                                   <div class="flex_row_start_start">
                                        <div class="seckill_time_item" v-for="(item, index) in time_list" :key="index"
                                             @click="changeList(index, item.stageId)">
                                             <div :class="currIndex == index ? 'active_time time_item' : 'time_item'">{{
                                                  item.time
                                             }}</div>
                                             <div :class="currIndex == index ? 'active_time_text time_text' : 'time_text'">
                                                  {{ item.state == 1 ? L['即将开始'] : L['已开抢'] }}
                                             </div>
                                        </div>
                                   </div>

                                   <div></div>
                              </el-scrollbar>
                         </nav>


                    </div>

               </section>


               <section class="main_bottom">
                    <div class="area_12">

                         <div class="goods_container flex_row_start_start">
                              <div class="goods_wrap " v-for="(goods, idx) in goods_list" :key="idx">

                                   <router-link :to="`/goods/detail?productId=${goods.productId}`" target="_blank">

                                        <div class="flex_column_between_center" style="height:100%">
                                             <div>
                                                  <div class="img">
                                                       <img :src="goods.mainImage" alt="">
                                                  </div>

                                                  <div class="text">
                                                       {{ goods.goodsName }}
                                                  </div>
                                             </div>


                                             <div class="price_remind flex_row_between_center">
                                                  <div class="price">
                                                       <div class="price_sec">
                                                            <template v-if="loginFlag">
                                                                 <span>￥</span>
                                                                 <span>{{ $getPartNumber(goods.seckillPrice, 'int') }}</span>
                                                                 <span>{{
                                                                      $getPartNumber(goods.seckillPrice, 'decimal')
                                                                 }}</span>
                                                            </template>
                                                            <span v-else>登录后显示价格</span>
                                                            <span>{{ L['秒杀价'] }}</span>
                                                       </div>
                                                       <div class="price_ori">￥{{ goods.productPrice }}</div>
                                                  </div>


                                                  <div class="remind" :class="{ cRemind: goods.isRemind }"
                                                       @click.prevent="setRemind(goods.stageProductId)">
                                                       {{ goods.isRemind ? L['取消提醒'] : L['设置提醒'] }}
                                                  </div>
                                             </div>
                                        </div>



                                   </router-link>


                              </div>
                         </div>


                         <div v-if="is_show_empty" class="empy_data">
                              <SldCommonEmpty :tip="L['今日无秒杀']"></SldCommonEmpty>
                         </div>


                    </div>

               </section>
          </div>

          <div v-else-if="!activity_open && loaded">
               <SldCommonEmpty :tip="L['模块未开启']"></SldCommonEmpty>
          </div>

          <SldLoginModal :visibleFlag="loginModalVisibleFlag" @closeLoingModal="loginModalVisibleFlag = false"
               @refreshInfo="refreshInfo" />
     </div>
</template>

<script>
import { ElMessage } from 'element-plus';
import { getCurrentInstance, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import SldCommonEmpty from '@/components/SldCommonEmpty'
import SldLoginModal from '@/components/loginModal'
import { useStore } from 'vuex';
export default {

     components: {
          SldCommonEmpty,
          SldLoginModal
     },

     setup() {
          const store = useStore()
          const loginFlag = ref(store.state.loginFlag);
          const { proxy } = getCurrentInstance()
          const L = proxy.$getCurLanguage()
          const loginModalVisibleFlag = ref(false)
          const route = useRoute()
          const seckillId = route.query.seckillId
          const activity_open = ref(false)
          const nav_list = ref([])
          const currIndex = ref(0)
          const is_show_empty = ref(false)
          const time_list = ref([])
          const stage_id = ref(null)
          const tabCurrent = ref('all')
          const current = ref(1)
          const label_id = ref(0)
          const goods_list = ref([])
          const goodsPage = ref({})
          const swiper_list = ref([])
          const loaded = ref(false)



          const getActivityGoods = () => {
               var param = {}
               param.labelId = label_id.value
               param.seckillId = seckillId
               param.current = current.value
               if (tabCurrent.value == 'all') {
                    param.stageId = stage_id.value
               }
               proxy.$get('v3/promotion/front/seckill/goodsList', param).then((res) => {
                    if (res.state == 200) {
                         let result = res.data
                         if (tabCurrent.value == 'all') {
                              is_show_empty.value = !time_list.value.length
                         } else {
                              is_show_empty.value = !result.list.length
                         }

                         goods_list.value = result.list
                         goodsPage.value = result.pagination
                    } else {
                         is_show_empty.value = true
                    }
               })
          }

          const ifOpen = () => {
               proxy.$get('v3/system/front/setting/getSettings', {
                    names: 'seckill_is_enable'
               }).then((res) => {
                    loaded.value = true
                    if (res.state == 200) {
                         if (res.data[0] == '1') {
                              activity_open.value = true
                              getSeckillList()
                              getBannerList()
                         } else {
                              activity_open.value = false
                         }
                    }
               })
          }


          const getSeckillList = () => {
               proxy.$get('v3/promotion/front/seckill/getSeckillLabel', { seckillId }).then((res) => {
                    if (res.state == 200) {
                         let result = res.data.list
                         if (result.length > 0) {
                              nav_list.value = result
                              currIndex.value = 0
                         } else {
                              is_show_empty.value = true
                         }
                         getSessionList()
                    } else if (res.state == 255) {
                         is_show_empty.value = true
                    }
               })
          }


          const getSessionList = () => {
               proxy.$get('v3/promotion/front/seckill/getSeckillStage', {
                    seckillId
               }).then((res) => {
                    if (res.state == 200) {
                         let result = res.data.list
                         if (result != null && result.length > 0) {
                              time_list.value = result
                              stage_id.value = time_list.value[currIndex.value].stageId

                              getActivityGoods()
                         } else {
                              is_show_empty.value = true
                         }
                    } else if (res.state == 255) {
                         is_show_empty.value = true
                    }
               })
          }

          //获取轮播图
          const getBannerList = () => {
               proxy.$get('v3/promotion/front/seckill/banner', {
                    seckillId
               }).then((res) => {
                    if (res.state == 200) {
                         var newList = []
                         if (res.data.banner) {
                              swiper_list.value = JSON.parse(res.data.banner)
                              swiper_list.value.map((item) => {
                                   if (item.imgUrl) {
                                        newList.push(item)
                                   }
                              })
                         }
                         swiper_list.value = newList
                    } else if (res.state == 255) {
                         is_show_empty.value = true
                    } else {

                    }
               })
          }


          // 切换分类
          const changeSort = (index, id) => {
               if (tabCurrent.value != index) {
                    tabCurrent.value = index
                    label_id.value = id
                    currIndex.value = 0
                    time_list.value = []
                    goods_list.value = []
                    getSessionList()
               }
          }


          const setRemind = (stageProductId) => {

               if (!store.state.loginFlag) {
                    loginModalVisibleFlag.value = true
                    return
               }


               let param = {
                    stageProductId
               }
               proxy.$post('v3/promotion/front/seckill/isRemind', param).then((res) => {
                    if (res.state == 200) {
                         ElMessage.success(res.msg)
                         getActivityGoods()
                    } else {
                         ElMessage.error(res.msg)

                    }
               })
          }

          // 切换场次
          const changeList = (index, id) => {
               currIndex.value = index
               stage_id.value = id
               getActivityGoods()
          }

          const refreshInfo = () => {
               history.go(0)
          }

          onMounted(() => {
               ifOpen()
          })

          return {
               changeList,
               setRemind,
               changeSort,
               is_show_empty,
               time_list,
               swiper_list,
               currIndex,
               nav_list,
               tabCurrent,
               current,
               label_id,
               activity_open,
               goods_list,
               goodsPage,
               seckillId,
               activity_open,
               loaded,
               loginModalVisibleFlag,
               refreshInfo,
               loginFlag,
               L
          }
     }
}
</script>

<style lang="scss" scoped>
.seckill_main {
     .area_12 {
          width: 1200px;
          margin: 0 auto;
     }

     .main_top {
          background-color: #fff;



          .category {
               padding-top: 20px;

               .cate_item {
                    margin: 0 10px;
                    padding: 8px 23px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #333333;

                    &.chosen {
                         background: #EC2116;
                         border-radius: 15px;
                         color: #fff;
                    }
               }
          }


          .swiper_banner {
               margin-top: 20px;
               margin-bottom: 20px;

               .el-carousel__item {

                    display: flex;
                    justify-content: center;
                    align-items: center;

                    div {
                         background-position: center center;
                         width: 1200px;
                         height: 457px;
                         position: absolute;
                         left: 50%;
                         top: 0;
                         margin-left: -600px;
                         background-repeat: no-repeat;
                         background-size: contain;
                    }
               }
          }

          .time_list {
               margin-bottom: 10px;

               .seckill_time_item {
                    margin-right: 40px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 120px;
                    cursor: pointer;

                    .time_item {
                         font-size: 16px;
                         color: $colorTitle1;
                         font-weight: bold;

                         &.active_time {
                              font-size: 16px;
                              font-weight: bold;
                              color: #ec2116 !important;
                         }
                    }

                    .time_text {
                         font-size: 16px;
                         color: $colorTitle2;
                         white-space: nowrap;
                         padding: 5px 10px;
                         line-height: 24px;
                    }

                    .active_time_text {
                         margin-top: 3px;
                         padding: 2px 10px;
                         background-color: #ec2116;
                         color: #fff !important;
                         border-radius: 17px;
                    }
               }
          }

     }

     .main_bottom {
          background: $colorK;
          padding-top: 28px;

          .goods_container {
               flex-wrap: wrap;

               .goods_wrap {
                    background: #fff;
                    padding: 10px;
                    height: 409px;
                    margin-right: 20px;
                    margin-bottom: 20px;

                    &:nth-child(n+4) {
                         margin-right: 0;
                    }

                    .img {
                         width: 265px;
                         height: 265px;

                         img {
                              width: 265px;
                              height: 265px;
                         }
                    }

                    .text {
                         margin-top: 10px;
                         width: 265px;
                         font-size: 14px;
                         font-family: Microsoft YaHei;
                         font-weight: 400;
                         color: #333333;
                         line-height: 18px;
                         display: -webkit-box;
                         -webkit-box-orient: vertical;
                         -webkit-line-clamp: 2;
                         overflow: hidden;
                         text-overflow: ellipsis;
                         word-break: break-all;
                    }

                    .price_remind {
                         width: 100%;

                         .price {
                              display: inline-block;

                              .price_sec {
                                   font-size: 12px;
                                   font-family: Microsoft YaHei;
                                   font-weight: 400;
                                   color: #FB3B48;

                                   span:nth-child(2) {
                                        font-weight: Bold;
                                        font-size: 15px;

                                   }

                                   span:last-child {
                                        margin-left: 10px;
                                   }
                              }

                              .price_ori {
                                   margin-top: 7px;
                                   font-size: 12px;
                                   font-family: Microsoft YaHei;
                                   font-weight: 400;
                                   text-decoration: line-through;
                                   color: $colorH;
                              }
                         }




                         .remind {
                              width: 85px;
                              height: 32px;
                              background: linear-gradient(90deg, #FF1233, #FD5845);
                              border-radius: 4px;
                              font-size: 16px;
                              font-family: Microsoft YaHei;
                              font-weight: 400;
                              color: #FFFFFF;
                              line-height: 32px;
                              text-align: center;
                              cursor: pointer;

                              &.cRemind {
                                   border: 1px solid $colorH;
                                   border-radius: 4px;
                                   background: #fff !important;
                                   color: #888888;
                              }
                         }
                    }
               }
          }
     }
}
</style>